import React from "react";
import Layout from "../../../other/layout";
import CommentCelaFonctionne from "../../../components/AC-StaticPages/Demarrez/ConceptionDeLogo/CommentCelaFonctionne/CommentCelaFonctionne";
import SEO from "../../../other/seo";

export default function conceptionDeLogo() {
  return (
    <Layout>
      <SEO title="Comment Cela Fonctionne" />
      <CommentCelaFonctionne />
    </Layout>
  );
}
