import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "gatsby";
import "../../../../../assets/css/global.css";
import styles from "./styles.module.css";
import step_one from "../../../../../assets/img/ld_stepone.gif";
import step_two from "../../../../../assets/img/ld_steptwo.gif";
import step_three from "../../../../../assets/img/ld_stepthree.gif";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
  }
}));

export default function ConceptionDeLogo() {
  const classes = useStyles();
  return (
    <div className={styles.container}>
      <h1 style={{ marginBottom: "20px" }}>Comment Cela Fonctionne</h1>
      <div className={styles.row}>
        <div className={styles.column}>
          <img
            className={styles.steps}
            src={step_one}
            alt="La première étape: sélection et approbation"
          ></img>
          <p>
            Choisissez un forfait de conception de logo. Ensuite, vous recevrez
            un courriel contenant un lien vers un formulaire par lequel vous
            nous donnerez des informations sur votre entreprise et identifierez
            les éléments que vous voudriez que votre logo comporte.
          </p>
        </div>
        <div className={styles.column}>
          <img
            className={styles.steps}
            src={step_two}
            alt="Deuxième étape: évaluation et modification"
          ></img>
          <p>
            À l'intérieur de 4 jours ouvrables, vous recevrez les premiers
            concepts graphiques pour votre logo. Choisissez votre préféré et
            nous y apporterons les modifications que vous désirez (le nombre de
            modifications possibles dépend du forfait que vous avez acheté).
          </p>
        </div>
        <div className={styles.column}>
          <img
            className={styles.steps}
            src={step_three}
            alt="Troisième étape: approbation et réception"
          ></img>
          <p>
            Lorsque vous êtes satisfait et avez approuvé le concept final, nous
            vous enverrons votre logo sur cédérom dans toute une variété de
            formats de fichiers. Nous conserverons également votre logo pour
            utilisation future sur les produits Deluxe que vous achèlterez.
          </p>
        </div>
      </div>
      <h2 style={{ margin: "30px 0 20px" }}>Foire aux question</h2>
      <div className={`${styles.row} ${styles.accordion_container}`}>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Pourquoi aurais-je besoin d'un logo personnalisé?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Un logo personnalisé distinguera votre entreprise de la
              concurrence en améliorant sa notoriété auprès des clients
              potentiels tout en faisant en sorte que vos clients établis se
              souviennent aisément de vous.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Pourquoi devrais-je confier àl Deluxe la conception de mon logo?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nos concepteurs de logo professionnels ont créé des identités
              vraiment uniques pour plus de 30 000 entreprises. Contrairement àl
              certains autres fournisseurs, notre équipe de concepteurs
              graphiques est basée ici même au Canada, et vous pouvez entrer
              directement en contact avec eux à tout moment au cours du
              processus de conception. Faites travailler pour vous certains des
              meilleurs talents au monde et offrez-vous le service personnalisé
              ainsi que tous les avantages que vous pouvez attendre de Deluxe.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>
              Comment se déroule le processus de conception de logo?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Après avoir choisi un forfait de conception de logo, vous recevrez
              un courriel contenant un lien qui vous mènera à un mémoire de
              conception que vous utiliserez pour faire connaître votre
              entreprise à nos concepteurs et nous faire part de vos préférences
              pour votre logo. Ils se serviront de ces renseignements pour créer
              des concepts de logo qu'ils vous soumettront dans un délai de 4
              jours ouvrables. Une fois que vous aurez choisi le concept que
              vous préférez, nos concepteurs graphiques effectueront des
              modifications en fonction de vos commentaires et de vos
              préférences.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography className={classes.heading}>
              Comment puis-je savoir quel genre de logo convient le mieux à mon
              entreprise?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Notre façon de procéder est faite pour vous aider àl décider. Nos
              concepteurs peuvent créer un logo très simple à partir d'une
              police, une illustration plus complexe ou encore à peu près
              n'importe quoi entre les deux. Gardez en tête que les meilleurs
              logos sont habituellement les plus clairs. Des concepts graphiques
              compliqués ont souvent l'air bien, mais ne sont pas nécessairement
              faciles àl reconnaˆtre ou à se rappeler. Pensez aussi aux usages
              futurs que vous en ferez. Par exemple, si vous prévoyez afficher
              votre logo sur un fond coloré ou l'apposer sur de petits objets
              tels que stylos et cartes d'affaires, un concept simple conviendra
              sûrement mieux.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography className={classes.heading}>
              Qu'arrive-t-il si je ne suis pas satisfait du premier concept de
              logo qui m'est proposé?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Si vous n'êtes pas satisfait des concepts de départ, nous créerons
              pour vous de nouveaux échantillons parmi lesquels vous pourrez
              faire votre choix. Nous vous contacterons pour en discuter plus
              longuement et continuerons à le faire jusqu'à ce que vous soyez
              entièrement satisfait.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            <Typography className={classes.heading}>
              Comment me livrerez-vous mon nouveau concept de logo?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Vous recevrez votre nouveau logo sur cédérom par la poste. Le
              cédérom contiendra votre logo sous divers formats pratiques, dont
              Illustrator, Photoshop, CorelDraw (Vector), BMP, EPS, GIF, JPEG et
              TIFF, et aussi en versions noir et blanc. De plus, Deluxe
              conservera votre concept final afin qu'il vous soit facile de
              commander par la suite des produits portant votre nouveau logo.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            <Typography className={classes.heading}>
              Comment puis-je être sûr que vous créerez un logo que je vais
              aimer?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nos concepteurs sont parmi les meilleurs dans le domaine. Notre
              équipe se servira des indications fournies dans votre mémoire de
              conception ainsi que d'une analyse comparative d'autres identités
              dans votre secteur d'activité afin de créer un logo professionnel
              qui correspond à vos goûts tout en vous distinguant de la
              concurrence. Jetez un œil à quelques-uns de nos exemples pour
              avoir une idée de ce que nous pouvons réaliser.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header"
          >
            <Typography className={classes.heading}>
              Puis-je contacter directement les concepteurs de logo?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Oui. De plus, notre équipe de conception graphique peut aussi vous
              contacter afin de clarifier un élément de votre mémoire de
              conception ou pour vous demander plus de détails sur votre
              entreprise.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9a-content"
            id="panel9a-header"
          >
            <Typography className={classes.heading}>
              Est-ce que vos concepteurs de logo sont à l'emploi de Deluxe?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Oui. Nous employons des concepteurs graphiques professionnels à
              l'interne, basés au Canada. Nous embauchons occasionnellement des
              concepteurs contractuels durant les périodes de pointe, mais à de
              rares exceptions.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10a-content"
            id="panel10a-header"
          >
            <Typography className={classes.heading}>
              Pourquoi offririez-vous de meilleurs services qu'un fournisseur
              local?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Les membres de notre équipe sont des concepteurs professionnels de
              logo à temps plein : ce sont des graphistes spécialisés en logos.
              Ils possèdent une expertise tout à fait particulière pour traduire
              votre vision en un logo que vos clients potentiels remarqueront et
              que vos clients établis se rappelleront.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <p style={{ marginTop: "30px" }}>
          <span>Vous avez des questions?</span> Communiquez avec nous au{" "}
          <a href="tel: 1-866-749-6327">1-866-749-6327</a>. Nos représentants
          sont disponibles du lundi au vendredi de 8 h à 20 h HNE. Lorsque vous
          achetez un logo, vous investissez dans votre entreprise et contribuez
          à sa croissance. Votre logo constitue la représentation visuelle de
          votre marque, et lorsqu’il est créé par un expert, il a le pouvoir de
          fidéliser votre clientèle et de vous distinguer de la concurrence. Nos
          spécialistes en conception de logos Deluxe mettent à profit leur
          talent pour créer un design professionnel qui saura créer une
          impression durable chez vos clients.
        </p>
      </div>
    </div>
  );
}
